<template>
<div id="job-box">
  <div class="detail-wrap">
     <div class="job-primary">
      <div class="info-primary">
        <h3>
          <span>{{jobInfo.positionName}}</span>
          <label>
             {{jobInfo.minimumWage}}<span v-show="jobInfo.maximumSalary">-{{jobInfo.maximumSalary}}</span>
          </label>
          <i v-show="jobInfo.collectionPosition==0" @click="collectPosition" class="el-icon-star-off"></i>
          <i v-show="jobInfo.collectionPosition==1" @click="cancelFavorite" class="el-icon-star-off star-on"></i>
        </h3>
        <div class="location">
          <p v-show="jobInfo.areaName">
            <img width="14px" height="18px" src="@/assets/imgs/location-icon.png">
            <span>{{jobInfo.areaName}}</span>
          </p>
          <p v-show="jobInfo.minimumEducationName">
            <img width="18px" height="18px" src="@/assets/imgs/edu-icon.png">
            <span>{{jobInfo.minimumEducationName}}</span>
          </p>
          <p v-show="jobInfo.workExperienceName">
             <img width="18px" height="18px" src="@/assets/imgs/time-icon.png">
             <span>{{jobInfo.workExperienceName}}</span>
          </p>
        </div>
        <div class="job-tags">
          <span v-for="(item,index) in benefitsMap" :key="index">{{item}}</span>
        </div>
      </div>
      <div class="job-op">
        <div class="btn-box" v-show="userInfo.type==1&&userInfo.phone" @click="jumpChat">
           <div class="chat-btn">立即沟通</div>
         </div>
         <div class="btn-box" v-show="userInfo.type==2">
            <div class="edit-btn" @click="jumpEdit" v-show="jobInfo.positionStatus==0||jobInfo.positionStatus==1||jobInfo.positionStatus==4">编辑</div>
            <div class="deliver-btn" v-show="jobInfo.positionStatus==1" @click="closePosition">关闭职位</div>
            <div class="deliver-btn" v-show="jobInfo.positionStatus==0||jobInfo.positionStatus==4" @click="openPosition">开放职位</div>
        </div>
      </div>
    </div>
    <div class="job-detail">
      <div class="job-require">
        <h3>职位要求</h3>
        <ul class="j-demand">
          <li v-show="jobInfo.recruitingNumbers">
            <span>招聘人数：</span><label>{{jobInfo.recruitingNumbers}}人</label>
          </li>
          <li v-show="jobInfo.genderRequirementsName">
            <span>性别要求：</span><label>{{jobInfo.genderRequirementsName}}</label>
          </li>
          <li v-show="jobInfo.ageRequirements">
            <span>年龄要求：</span>
            <label v-show="jobInfo.ageMinimum">{{jobInfo.ageMinimum}}~</label>
            <label v-show="jobInfo.ageRequirements">{{jobInfo.ageRequirements}}岁</label>
          </li>
        </ul>
        <ul class="job-sec-text" v-show="jobInfo.jobDescription">
          <h4>岗位职责:</h4>
          <p>{{jobInfo.jobDescription}}</p>
        </ul>
        <div class="boss-info" @click="jumpCompany">
          <img :src="jobInfo.headSculpture" />
          <p>
            <span>{{jobInfo.publisherName}}</span>
            <label>{{jobInfo.enterpriseName}} · {{jobInfo.position}}</label>
          </p>
        </div>
      </div>
      <div class="company-address" @click="jumpCompany">
        <h4>工作地址</h4>
        <p>
          <img src="../assets/imgs/company/location-blue.png"><span>{{jobInfo.workAddress}}{{jobInfo.houseNumber}}</span>
        </p>
        <h4>公司信息</h4>
        <div class="company-profile">
          <img :src="jobInfo.enterpriseLogo">
          <p>
            <span>{{jobInfo.enterpriseName}}</span>
            <label>{{jobInfo.enterpriseNatureName}} <em v-show="jobInfo.scaleName">|</em> {{jobInfo.scaleName}} <em v-show="jobInfo.companyIndustryName">|</em> {{jobInfo.companyIndustryName}}</label>
          </p>
        </div>
      </div>
    </div>
    <div class="job-sider">
      <div class="sider-company">
        <h3>工商信息</h3>
        <p>
          <span>企业类型：</span>
          <label>{{companyInfo.compositionForm}}</label>
        </p>
        <p>
          <span>统一社会信用代码：</span>
          <label>{{companyInfo.socialCreditCode}}</label>
        </p>
        <p>
          <span>成立日期：</span>
          <label>{{companyInfo.establishedTime}}</label>
        </p>
        <p>
          <span>注册资本：</span>
          <label>{{companyInfo.registeredCapital}}</label>
        </p>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import { getJobDetails,addBrowsePosition, getCompanyDetail, setPositionStatus,collectPosition,cancelFavorite } from "@/api/index.js";
export default {
  name: "jobDetail",
  data() {
    return {
      jobInfo: {},
      benefitsMap: [],
      companyInfo: {},
      userInfo:{}
    }
  },
  mounted(){
     this.getJobDetails()
     this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
  },
  methods: {
    addBrowsePosition(){
      addBrowsePosition({
        enterpriseId: this.jobInfo.enterpriseId,
        positionInfoId: this.jobInfo.id,
        publisherId: this.jobInfo.publisherId
      })
    },
    collectPosition(){
      collectPosition({
        positionInfoId:this.jobInfo.id
      }).then(res=>{
         if(res.code==200){
           this.$message({
            message: '收藏成功',
            type: 'success'
           })
           this.getJobDetails()
        }
      })
    },
    cancelFavorite(){
      cancelFavorite(this.jobInfo.collectId).then(res=>{
        if(res.code==200){
           this.$message({
            message: '取消收藏成功',
            type: 'success'
           })
           this.getJobDetails()
        }
      })
    },
    jumpChat(){
      this.$router.push({
        path: '/chat',
        query: {
          positionId: this.$route.query.positionId,
          recvId:this.jobInfo.publisherId
        }
      })
    },
    getJobDetails() {
      getJobDetails({
        positionInfoId: this.$route.query.positionId
      }).then(res => {
        this.jobInfo = res.data
        this.benefitsMap = []
        var materialBenefits = res.data.materialBenefits.split(',')
        var benefitsList = JSON.parse(localStorage.getItem('benefitsList'))
        materialBenefits.forEach(g => {
          benefitsList.forEach(s => {
            if (g == s.dictValue) {
              this.benefitsMap.push(s.dictLabel)
            }
          })
        })
        this.addBrowsePosition()
        this.getCompanyDetail()
      })
    },
    openPosition(){
        setPositionStatus({
        id: this.jobInfo.id,
        positionStatus: "1"
      }).then(res => {
        if (res.code == 200) {
          this.$message({
            message: '开放职位成功',
            type: 'success'
          })
          this.$router.push({
            path: '/mine'
          })
        }
      })
    },
    closePosition() {
      setPositionStatus({
        id: this.jobInfo.id,
        positionStatus: "4"
      }).then(res => {
        if (res.code == 200) {
          this.$message({
            message: '关闭职位成功',
            type: 'success'
          })
          this.$router.push({
            path: '/mine'
          })
        }
      })
    },
    jumpEdit() {
      this.$router.push({
        path: '/publish',
        query: {
          positionId: this.$route.query.positionId
        }
      })
    },
    jumpCompany() {
      this.$router.push({
        path: '/companyDetail',
        query: {
          enterpriseId: this.jobInfo.enterpriseId
        }
      })
    },
    getCompanyDetail() {
      getCompanyDetail({
        enterpriseId: this.jobInfo.enterpriseId
      }).then(res => {
        this.companyInfo = res.data
      })
    }
  }
};
</script>

<style lang="scss" scoped>
#job-box {
  width: 100%;
  min-height: 800px;
  background: linear-gradient(0deg, #F5F5F5 1%, #D6E9FF 100%);
  overflow: hidden;
  padding-top: 16px;
}

.detail-wrap {
  width: 1200px;
  min-height: 500px;
  overflow: hidden;
  margin: 0 auto;
}

.job-primary {
  min-height: 70px;
  background: #FFFFFF;
  border-radius: 10px;
  margin-bottom:13px;
  padding:20px 30px;
  overflow: hidden;

  .info-primary {
    float: left;
    min-width: 300px;

    h3 {
      font-size: 20px;
      font-weight: 500;
      color: #222222;

      label {
        display: inline-block;
        margin-left:30px;
        letter-spacing: 1px;
        color: #FF2400
      }
      i{
        color: #999;
        margin-left: 30px;
        font-size:23px;
        position: relative;
        top: 2px;
        cursor: pointer;
      }
      .star-on{
        color: #0077FF;
      }
    }
  }

  .location {
    margin-top: 16px;
    margin-bottom: 6px;
    p{
      display: inline-block;
    }

    img {
      margin-right: 10px;
      position: relative;
      top: 3px;
    }

    span {
      display: inline-block;
      font-size: 16px;
      color: #999999;
      margin-right: 50px;
    }
  }

  .job-tags {
    margin-top:3px;
    width: 1000px;

    span {
      display: inline-block;
      min-width: 40px;
      height: 24px;
      line-height: 24px;
      text-align: center;
      background: #E5F1FF;
      border-radius: 2px;
      font-size: 13px;
      color: #0077FF;
      padding: 0 12px;
      margin-right: 11px;
      margin-top: 10px;
    }
  }

  .job-op {
    float: right;

    p {
      margin-right: 10px;
      margin-top: 65px;
      text-align: right;
      font-size: 16px;
      color: #222222;

      span {
        color: #999;
        display: inline-block;
        margin-left: 21px;
      }
    }
  }

  .btn-box {
    .edit-btn {
      width: 122px;
      height:40px;
      line-height: 40px;
      text-align: center;
      color: #fff;
      font-size: 15px;
      cursor: pointer;
      font-weight: 500;
      background: #0077FF;
      border-radius: 4px;
      float: left
    }

    .deliver-btn {
      width: 122px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      color: #fff;
      font-size: 16px;
      cursor: pointer;
      font-weight: 500;
      float: left;
      background: #07C160;
      border-radius: 4px;
      margin-left: 23px;
    }
    .chat-btn{
      width: 122px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      color: #fff;
      font-size: 16px;
      cursor: pointer;
      font-weight: 500;
      background: #0077FF;
      border-radius: 4px;
      float: left
    }
  }
}

.job-detail {
  float: left;

  .job-require {
    width: 742px;
    min-height: 150px;
    padding: 24px 20px 23px;
    border-radius: 10px;
    background: #FFFFFF;
    overflow: hidden;
  }

  h3 {
    margin-bottom: 18px;
  }

  .j-demand {
    margin-left: 30px;
    font-size: 16px;
    color: #666666;
    overflow: hidden;

    li {
      width: 200px;
      float: left;
    }
  }

  .job-sec-text {
    font-size: 16px;
    clear: both;
    margin-top: 24px;
    font-weight: 500;
    color: #666;
    margin-left: 30px;
    white-space: pre;
    padding-bottom: 12px;

    h4 {
      color: #222222;
      font-weight: 600;
      margin-bottom: 16px;
    }

    p {
      line-height: 28px;
      white-space: pre-wrap;
    }
  }

  .boss-info {
    margin-top: 10px;
    width: 100%;
    height: 100px;
    border-top: 1px solid #DDDDDD;
    cursor: pointer;

    img {
      width: 70px;
      height: 70px;
      display: block;
      border-radius: 50%;
      float: left;
      margin-top: 22px;
    }

    p {
      float: left;
      margin-top: 27px;
      font-size: 20px;
      font-weight: 500;
      margin-left: 15px;
      color: #222222;

      label {
        display: block;
        font-size: 16px;
        font-weight: 400;
        color: #666666;
        margin-top: 13px;
      }
    }

    .boss-online-tag {
      float: right;
      background: #ccc;
      padding: 2px;
    }
  }

  .company-address {
    clear: both;
    padding: 30px 20px 10px;
    margin-bottom: 13px;
    background: #fff;
    margin-top: 13px;
    overflow: hidden;
    cursor: pointer;
    border-radius: 10px;

    h4 {
      font-size: 20px;
      font-weight: 500;
      color: #222222;
      margin-bottom: 15px;
    }

    p {
      margin-bottom: 20px;

      img {
        width: 18px;
        height: 24px;
        position: relative;
        top: 3px;
      }

      span {
        font-size: 16px;
        color: #222222;
        display: inline-block;
        margin-left: 10px;
      }
    }

    .company-profile {

      img {
        width: 60px;
        height: 60px;
        display: block;
        float: left;
        margin-right: 20px;
      }

      p {
        width: 400px;
        float: left;
      }
      em{
         margin: 0 6px;
        color: rgb(223, 221, 221);
      }
      span {
        margin-left: 0;
        font-size: 18px;
        color: #222222;
      }

      label {
        display: block;
        font-size: 15px;
        color: #666;
        margin-top: 15px;
      }
    }
  }
}

.job-sider {
  float: left;
  margin-left: 13px;

  .sider-company {
    width: 365px;
    min-height: 200px;
    background: #FFFFFF;
    border-radius: 10px;
    padding: 30px 20px;
  }

  h3 {
    font-size: 20px;
    font-weight: 500;
    color: #222222;
  }

  p {
    margin-top: 18px;

    span {
      display: block;
      margin-bottom: 9px;
      font-size: 16px;
      color: #999999;
    }

    label {
      font-size: 16px;
      color: #222222;
    }
  }
}
</style>
